import { render, staticRenderFns } from "./Warranty.vue?vue&type=template&id=2a6c649a&scoped=true&"
import script from "./Warranty.vue?vue&type=script&lang=js&"
export * from "./Warranty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6c649a",
  null
  
)

export default component.exports